.desktop-navbar--header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .15);
}

.ingresa-button {
  border: 1.2px solid #002594;
  padding: 0px 16px;
  margin: 0px;
  margin-right: 12px;
  height: 30px;
  border-radius: 14px;
  font-weight: 700;
  color: #002594;
}

.ingresa-button:hover{
  color: #002594 !important;
}

.ingresa-button--icon {
  stroke: #002594;
  stroke-width: 50;
}
