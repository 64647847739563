.desktop-menu--item .text-primary:focus, a.text-primary:hover {
  /* color: rgb(0, 10, 77) !important; */
  color: #002594 !important;
}

.desktop-menu {
  border: 0;
  min-width: 600px;
  color: #5c6a74;
  line-height: 70px;
}

.submenu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2px;
}

.desktop-menu--item {
  margin: 0 20px !important;
  top: 0 !important;
}

.down-icon {
  margin: 0 !important;
  font-size: 80% !important;
}