.footer--wrapper {
  background-color: #001399;
}

.footer--container {
  max-width: 1324px;
  padding: 24px;
  margin: 0 auto;
}

.footer--logo-container {
  display: flex;
}

@media (max-width: 991px) {
  .footer--logo-container{
    justify-content: center;
  }
}

.footer--item-container {}

@media (max-width: 991px) {
  .footer--item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.footer--item-title {
  font-weight: bold;
  margin: 0;
  color: white;
}

.footer--item-link {
  color: white;
  margin: 0;
  font-size: 12px;
  line-height: 24px;
}

.footer--bottom-text {
  color: #bab7b7;
  font-size: 12px;
}

@media (max-width: 991px) {
  .footer--bottom-text {
    text-align: center;
  }
}