.navButtons button{
    color: white;
    height: 70px;
    border-radius: 0;
}

.navButtons{
    margin-left: 15px;
    white-space: nowrap;
}

//Mobile nav
.navButtons.isMobile {
    width: calc(100vw - 220px);
    max-width: fit-content;
    overflow: auto;
    overflow-y: hidden;
}

.canalButton{
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.loading{
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat .chat-content .chat-content-body {
    height: calc(100vh - 60px - 76px - 25px - 70px - 60px - 30px);
    padding: 15px;
    overflow-y: auto;
}

.chat-content-body{
    min-height: 400px;
}

.side-nav-right{
    background-color:rgb(42, 53, 66);
}

.nav-container{
    display: flex !important;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.logo img:hover{
    cursor: pointer;
}
