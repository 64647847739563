@font-face {
  font-family: 'Fira Sans';
  src: url("./assets/FiraSans/FiraSans-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url("./assets/FiraSans/FiraSans-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url("./assets/FiraSans/FiraSans-Medium.ttf") format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url("./assets/FiraSans/FiraSans-SemiBold.ttf") format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url("./assets/FiraSans/FiraSans-SemiBold.ttf") format('truetype');
  font-weight: bold;
  font-style: normal;
}

* {
  font-family: 'Fira Sans' !important;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #1F2AA5;
}
