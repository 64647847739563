.mobile-navbar--header {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, .15);
}

.mobile-navbar--icon {
  width: 75px;
  font-size: 18px;
  color: #002594;
}

.mobile-navbar--profile-container {
  width: 75px;
  display: flex;
  justify-content: center;
}
