.nav-profile--menu {
  border: none;
  line-height: 70px;
}
.nav-profile--info {
  display: flex;
  gap: 12px;
  padding: 12px;
  align-items: center;
  border-bottom: 1px solid rgb(226, 226, 226);
}

.nav-profile--submenu{
  margin: 0 !important;
  top: 0 !important;
}

.nav-profile--popup {
  width: 14.5rem;
}